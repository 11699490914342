.team {
    width: 100%;
    font-size: 16px;
}

.team_box {
    padding: 10px;
}

.team_top {
    width: 90%;
    height: 156px;
    background: url('../../public/assets/index/salesBack.png') no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    font-size: 13px;
    padding: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 8px;
}

.team_info {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.team_topNum {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.team_topNum:first-child {
    border-right: 1px solid rgba(35, 47, 62, 1);
}

.team_topNum>div {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.team_topNum>div>p:last-child {
    font-size: 23px;
    font-weight: bold;
    margin-left: 5px;
}

.team_get {
    width: 90%;
    height: 41px;
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team_table {
    width: 90%;
    margin: 15px auto;
    position: relative;
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0px 4px 6px 0px rgba(42, 79, 174, 0.15)
}

.team_num {
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: space-around;
}

.team_list {
    width: 90%;
    background-color: #fff;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden;
    font-size: 13px;
}

.team_num>div {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 10px;
    color: rgba(35, 47, 62, 1);
}

.team_num>div p {
    font-weight: bold;
    font-size: 17px;
    margin: 0 3%;
}

.team_list>div:first-child {
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #333;
    color: #fff;
}

.team_list>div>p {
    width: 105px;
    text-align: center;
}

.list_content {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.list_content text {
    width: 33%;
    text-align: center;
}