.Withdraw {
    width: 100%;
    font-size: 16px;
}

.Withdraw_box {
    margin: 10px auto;
    width: 90%;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.withdraw_from {
    width: 100%;
    height: 72px;
    margin: 0 0 12px 0;
}

.withdraw_from input {
    border: 1px solid rgba(217, 217, 217, 1);
    width: 100%;
    height: 31px;
    border-radius: 5px;
    background-color: rgba(242, 242, 242, 1);
    margin: 8px 0;
    padding: 0 5px;
}

.withdraw_from span {
    font-weight: bold;
    font-size: 18px;
}

.withdraw_button {
    width: 90%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 8px;
    background-color: #EE264D;
}

.available {
    display: flex;
    align-items: center;
    justify-content: space-between;
}