.area_box {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: #ededed;
    padding: 10px 0;
}

input:disabled {
    border: 1px solid #DDD;
    background-color: #fff;
    color: #ACA899;
}

.input_area {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.input_area p {
    text-align: center;
    font-size: 23px;
}

.input_area>p:last-child {
    display: flex;
    min-width: 70px;
    justify-content: center;
    align-items: center;
    height: 96%;
    font-size: 15px;
    color: #fff;
    border-radius: 8px;
    background-color: #EE264D;
}

.input_area input {
    border: none;
    height: 100%;
    width: 100%;
    outline: none;
    padding: 0 8px;
    border-radius: 8px;
    margin: 0 10px;
}

.area_list {
    width: 90%;
    height: auto;
    margin: 2% 5%;
    color: #fff;
    overflow: auto;
}

.area_item {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px gainsboro solid;
    color: #000;
    font-size: 16px;
}

.area_item img {
    width: 40px;
    margin: 0 10px;
}