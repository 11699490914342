.Register {
    width: 100%;
    height: 100vh;
    background: url('/public/assets/index/newLogin.png') no-repeat;
    background-size: cover;
    position: relative;
}

.lang {
    width: 100%;
    text-align: end;
    padding: 5px 10px;
    font-size: 16px;
    color: #fff;
}

.from_Register {
    width: 90%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%);
}

.Register_from {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 0;
}

.logo {
    width: 117px;
    height: 110px;
}

.input_from {
    width: 90%;
    height: 35px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px auto 10px;
}

.input_from input {
    border: none;
    height: 100%;
    width: 83%;
    outline: none;
    padding: 0 5px;
}

.password {
    width: 100% !important;
}

.Remember {
    margin: 15px auto;
    width: 90%;
    font-size: 15px;

}

.Register_submit {
    width: 90%;
    margin: 30px auto 0;

}

.Register_submit button {
    background-color: #009995;
    box-shadow: rgba(41, 5, 5, 0.15) 0px 5px 5px;
}

.Register_text {
    color: rgba(113, 113, 128, 1);
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 5px 0;
}

.Register_text>p:last-child {
    color: #009995;
    margin: 0 5px;
}