.order {
    width: 100%;
    font-size: 16px;
}

.order_tab {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: rgba(36, 47, 61, 1);
    margin: 10px 0;
}

.order_complete {
    width: 46%;
    height: 41px;
    background-color: #EE264D;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
}

.order_undone {
    width: 46%;
    height: 41px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color: #E6E6E6;
    align-items: center;
}

.order_card1 {
    width: 100%;
    min-height: 80vh;
    padding: 0 10px;
    position: relative;
}

.adm-error-block {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.dataList_order {
    width: 100%;
    padding: 5px 0;
}

.order_list {
    margin: 10px 0;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.card_tops {
    height: 50%;
    border-bottom: 1px dashed gray;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.card_tops img {
    border-radius: 10px;
    height: 105px;
    width: 105px;
    object-fit: cover;
}

.list_texts {
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.list_texts>p:first-child {
    font-size: 12px;
    color: rgba(55, 71, 90, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.list_texts>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3% 0;
    color: rgba(35, 47, 62, 1);
    font-size: 17px;
    font-weight: 600;
}

.list_texts>div>p:last-child {
    color: rgba(3, 74, 255, 1);
    font-size: 12px;
}

.card_down {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    padding: 5px 0;
}

.card_down>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1a232d;
    margin: 2px 0;
}

.card_down>div>p:last-child {
    color: #4e5053;
    font-size: 15px
}

.order_window {
    border-radius: 10px;
    box-shadow: 0px 4px 6px 0px rgba(42, 79, 174, 0.15);
    font-size: 14px;
    padding: 10px 5px;
    margin: 0 0 10px;
}

.capital_evaluate {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pop_but {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EE264D;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
}